<template>
	<div>
		<h4 class="h4_title">出金提交</h4>
		
		<van-popup v-model="showCurrency" round position="bottom">
			<van-picker
				title="出金货币"
				show-toolbar
				value-key="balance_show"
				:columns="currencys"
				:default-index="indexCurrency"
				@cancel="showCurrency = false"
				@confirm="onConfirmCurrency"
			/>
		</van-popup>

		<van-cell-group>
			<van-cell title="可提金额" :value="balance_select_show" @click="showCurrency = true" is-link/>
		</van-cell-group>
		
		<!--
		<h4 class="h4_title">选择收款账户</h4>
		
		<van-cell title="选择收款账户" is-link :url="withdraw_banks" />
		-->
		
		<template v-if="formItem.bank_type && formItem.bank_info">
			<h4 class="h4_title">收款账户信息</h4>
			<van-cell-group>
				<van-cell title="账户类型" :value="bank_type_text" />
				<van-cell v-for="(item, key) in bank_info" :title="key" :value="item" :key="key" />
			</van-cell-group>
		</template>

		<h4 class="h4_title">出金金额</h4>

		<van-form @submit="formSubmit">

			<van-field
				clearable
				type="digit"
				v-model="formItem.total"
				name="total"
				label="出金金额"
				placeholder="出金金额"
				:rules="[{ required: true, trigger: 'onChange', message: '请填写出金金额' }]"
			/>
			
			<div class="common_block" v-show="max_show || min_show">
				<h4 style="margin: 10px 0">出金限额</h4>
				<div class="description">
					<p v-show="max_show">最大出金：{{max_show}}</p>
					<p v-show="min_show">最小出金：{{min_show}}</p>
				</div>
			</div>
			
			<div class="common_block">
				<van-button type="warning" block native-type="submit">提交</van-button>
			</div>
		</van-form>

		<div class="common_block">
			<p style="float: left;color: blue;" @click="goBank">银行卡审核</p>
			<p style="float: right;color: blue;" @click="goHistory">出金记录</p>
			<div style="clear: both;"></div>
		</div>
		
		<div class="common_block">
			<h5 style="margin: 30px 0 10px 0">出金说明</h5>
			<div class="description" v-html="withdraw_tip"></div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'withdraw_index',
		data() {
			return {
				formItem: {
					market: 'HK',
					symbol: "",
					total: "",
					token: "",
					bank_type: "",
					bank_info: ""
				},

				currencys: [],
				indexCurrency: 0,
				showCurrency: false,
				balance_select_show: "",
				
				bank_type_text: "",
				bank_info: {},
				
				hk_balance_show: "",
				hk_balance: 0,
				
				min: 0,
				min_show: "",
				max: 0,
				max_show: "",
				
				withdraw_tip: "",

				withdraw_banks: ""
			}
		},
		mounted() {
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			} else {
				this.formItem.token = this.$route.query.token

				this.withdraw_banks = '#/withdraw/banks?token=' + this.$route.query.token

				var bank_type = window.sessionStorage.getItem('bank_type')
				var bank_info = window.sessionStorage.getItem('bank_info')

				if (bank_type) {
					this.formItem.bank_type = bank_type

					switch (bank_type) {
						case '1':
							this.bank_type_text = '国内银行账户'
							break

						case '2':
							this.bank_type_text = '海外银行账户'
							break

						case '3':
							this.bank_type_text = 'USDT'
							break
					}
				} else {
					this.formItem.bank_type = 0
					this.bank_type_text = ''
				}

				if (bank_info) {
					this.formItem.bank_info = bank_info
					this.bank_info = JSON.parse(bank_info)
				} else {
					this.formItem.bank_info = ''
					this.bank_info = {}
				}

				window.sessionStorage.removeItem('bank_type')
				window.sessionStorage.removeItem('bank_info')

				this.init()
			}
		},
		methods: {
			init() {
				this.$axios({
					method: 'post',
					url: 'cash_withdraw/index', 
					data: {
						token: this.formItem.token
					}
				}).then ((res) => {
					if (res.success) {
						this.currencys = res.data.currencys
						this.balance_select_show = this.currencys[this.indexCurrency]['balance_show']
						this.withdraw_tip = res.data.withdraw_tip

						if (this.formItem.bank_type == '' || this.formItem.bank_info == '') {
							this.bank_type_text = res.data.bank_type_text
							this.bank_info = res.data.bank_info

							this.formItem.bank_type = res.data.bank_type
							this.formItem.bank_info = JSON.stringify(res.data.bank_info)
						}

						window.sessionStorage.setItem('name', res.data.name)
						window.sessionStorage.setItem('customer_bank', res.data.customer_bank)

						this.onConfirmCurrency(this.currencys[this.indexCurrency], this.indexCurrency)
					} else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
			},
			formSubmit() {
				
				if (this.formItem.bank_type == '' || this.formItem.bank_info == '') {
					this.$dialog.alert({
						message: '选择收款账户'
					})
					
					return false
				}

				var total = parseFloat(this.formItem.total)
				
				if (total <= 0) {
					this.$dialog.alert({
						message: '请填写出金金额'
					})
					
					return false
				}
				
				if (total > this.balance) {
					this.$dialog.alert({
						message: '金额错误，最大可提金额：' + this.balance_select_show
					})
					
					return false
				}

				if (this.max != 0 && total > this.max) {
					this.$dialog.alert({
						message: '金额错误，最高出金：' + this.max_show
					})
					
					return false
				}

				if (this.min != 0 && total < this.min) {
					this.$dialog.alert({
						message: '金额错误，最低出金：' + this.min_show
					})
					
					return false
				}
				
				this.$toast.loading({
					message: '请稍等...',
					forbidClick: true,
				})
				
				this.$axios({
					method: 'post',
					url: 'cash_withdraw/insert', 
					data: this.formItem
				}).then ((res) => {
					
					this.$toast.clear()
					
					if (res.success) {
						this.$toast.success({
							message:'出金申请已成功提交'
						})
						
						this.balance_select_show = res.data.currencys[this.indexCurrency]['balance_show']
						this.balance = res.data.currencys[this.indexCurrency]['balance']
						
						this.formItem.total = "0"
						this.formItem.bank_type = ""
						this.formItem.bank_info = ""


						window.sessionStorage.removeItem('bank_type')
						window.sessionStorage.removeItem('bank_info')
						window.sessionStorage.removeItem('name')
						window.sessionStorage.removeItem('bank_info_usdt')
						window.sessionStorage.removeItem('bank_info_hk')
						window.sessionStorage.removeItem('bank_info_mainland')
					} else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
			},
			goHistory() {
				this.$router.push('/withdraw/history?token=' + this.formItem.token)
			},
			goBank() {
				this.$router.push('/customer/bank?token=' + this.formItem.token)
			},
			onConfirmCurrency(value, index) {
				this.indexCurrency = index

				this.balance_select_show = value.balance_show
				this.balance = value.balance

				this.formItem.symbol = value.symbol

				if (parseFloat(value.min_withdraw) != 0) {
					this.min = value.min_withdraw
					this.min_show = value.min_withdraw_show
				} else {
					this.min = 0
					this.min_show = ''
				}

				if (parseFloat(value.max_withdraw) != 0) {
					this.max = value.max_withdraw
					this.max_show = value.max_withdraw_show
				} else {
					this.max = 0
					this.max_show = ''
				}

				this.showCurrency = false
			}
		},
	}
</script>
